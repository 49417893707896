import { template as template_1d3dc65d004046de9a8c20f395861137 } from "@ember/template-compiler";
const FKLabel = template_1d3dc65d004046de9a8c20f395861137(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
