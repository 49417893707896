import { template as template_d15b5ee34e8b47ea9c0a7505f7fcdcb4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d15b5ee34e8b47ea9c0a7505f7fcdcb4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
