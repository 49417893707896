import { template as template_2388c849c16649a0a52dd77b93fb8205 } from "@ember/template-compiler";
const WelcomeHeader = template_2388c849c16649a0a52dd77b93fb8205(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
